body {
  background-color: black;
}

.nftImg {
  transition: .2s ease;
  opacity: 1;
}

.nftImgB {
  transition: .8s ease;
  display: none;
  opacity: 0;
}

.NftTit {
  transition: 0.2s ease;
  color: white;
  font-size: 22px;
  font-weight: bold;
}

.nftCont:hover .nftImg {
  display: none;
  opacity: 0;
}

.nftCont:hover .nftImgB {
  display: block;
  opacity: 1;
}

.nftCont:hover .NftTit {
  color: #49684f;
}